// banners
export const GET_BANNERS = '/banners';
export const GET_BANNER = '/banners/';
export const TOGGLE_BANNER_STATUS = '/banners/status/';
export const ADD_BANNER = '/banners/add';
export const UPDATE_BANNER = '/banners/';
export const DELETE_BANNER = '/banners/';

// categories
export const GET_CATEGORIES = '/categories';
export const GET_CATEGORY = '/categories/';
export const TOGGLE_CATEGORY_STATUS = '/categories/status/';
export const ADD_CATEGORY = '/categories/add';
export const UPDATE_CATEGORY = '/categories/';
export const DELETE_CATEGORY = '/categories/';

// services
export const GET_SERVICES = '/services';
export const GET_ACTIVE_SERVICES = '/services/active';
export const GET_SERVICE = '/services/';
export const GET_PARTNER_SERVICE = '/services/partners';
export const TOGGLE_SERVICE_STATUS = '/services/status/';
export const ADD_SERVICE = '/services/add';
export const UPDATE_SERVICE = '/services/';
export const DELETE_SERVICE = '/services/';

// partners
export const GET_PARTNERS = '/users/role/2';
export const GET_ACTIVE_PARTNERS = '/users/partners/active';
export const GET_PARTNER = '/users/partners/';
export const APPROVE_PARTNER = '/users/partners/approve/';
export const FIX_PARTNER_PHONE = '/users/partners/phone/';
export const ADD_PARTNER = '/users/partners/add';
export const UPDATE_PARTNER = '/users/partners/';
export const PAID_PARTNERS = '/users/partners/subscribed/paid';
export const FREE_PARTNERS = '/users/partners/subscribed/free';
export const UNSUBSCRIBED_PARTNERS = '/users/partners/unsubscribed';
export const EXTEND_PARTNER_SUBSCRIPTION = '/users/partners/extend/';
export const EXPIRED_PARTNERS = '/users/partners/expired';
export const UPDATE_PARTNER_STATUS = '/users/partners/status/';

// subscriptions
export const GET_SUBSCRIPTIONS = '/subscriptions';

// Mail Setup
export const GET_MAIL_CONFIG = '/mail';
export const UPDATE_MAIL_CONFIG = '/mail';

// admins
export const GET_ADMINS = '/users/role/1';
export const GET_ADMIN = '/users/admins/';
export const ADD_ADMIN = '/users/admins/add';
export const UPDATE_ADMIN = '/users/admins/';

// customers
export const GET_CUSTOMERS = '/users/role/3';
export const GET_CUSTOMER = '/users/customers/';

// payment gateway
export const GET_PAYMENT_GATEWAY = '/payment';
export const UPDATE_PAYMENT_GATEWAY = '/payment';
export const TOGGLE_PAYMENT_GATEWAY_STATUS = '/payment/status';

// sms gateway
export const GET_SMS_GATEWAY = '/sms';
export const UPDATE_SMS_GATEWAY = '/sms';
export const TOGGLE_SMS_GATEWAY_STATUS = '/sms/status';

// subscription plans
export const GET_SUBSCRIPTION_PLANS = '/subscriptions';
export const UPDATE_SUBSCRIPTION_PLAN = '/subscriptions/';

// newsletter
export const GET_NEWSLETTER_EMAILS = '/newsletters';

// store setup
export const GET_STORE_SETUP = '/store';
export const UPDATE_STORE_SETUP = '/store';
export const TOGGLE_STORE_SETUP_STATUS = '/store/status';

// time slots
export const GET_TIME_SLOTS = '/time-slots';
export const GET_ACTIVE_TIME_SLOTS = '/time-slots/active';
export const GET_TIME_SLOT = '/time-slots/';
export const TOGGLE_TIME_SLOT_STATUS = '/time-slots/status/';
export const ADD_TIME_SLOT = '/time-slots/add';
export const UPDATE_TIME_SLOT = '/time-slots/';
export const DELETE_TIME_SLOT = '/time-slots/';

// auth
export const LOGIN = '/auth/login';
export const GET_USER = '/auth/user';

// terms
export const GET_TERMS = '/content/terms';
export const ADD_UPDATE_TERMS = '/content/1';

// policies
export const GET_POLICIES = '/content/policies';
export const ADD_UPDATE_POLICIES = '/content/2';

// messages
export const GET_MESSAGES = '/messages';

// notifications
export const MARK_NOTIFICATIONS_AS_READ = '/notifications/read';
export const MARK_NOTIFICATION_AS_READ = '/notifications/read/';

// payments
export const GET_PAYMENTS = '/pay';
export const GET_PAYMENT = '/pay/';

// contact us messages
export const GET_CONTACT_US_MESSAGES = '/contact-messages';

// contact us messages
export const GET_APPOINTMENTS = '/appointments';

// orders
export const GET_ORDERS = '/orders';
export const GET_ORDER = '/orders/';
export const ADD_ORDER = '/orders/add';
export const UPDATE_ORDER = '/orders/';
export const GET_ORDERS_BY_STATUS = '/orders/status/';
