import moment from 'moment';
import React from 'react';
import { Badge, NavLink, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import { statusClass } from './utils';
import { useHistory } from 'react-router-dom';

const OrdersTable = ({ columns, orders }) => {
  const history = useHistory();

  return (
    <Table borderless hover className="table-alternate text-nowrap mb-0">
      <thead>
        <tr>
          {columns?.map((column, index) => (
            <th key={index} className={column?.className}>
              {column?.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {orders?.map((row, index) => (
          <tr key={index}>
            <td>
              <NavLink
                onClick={() => history.push(`/orders/view/${row?.id}`)}
                to={`/orders/view/${row?.id}`}>
                {row?.id}
              </NavLink>
            </td>
            <td className="text-center">
              <span>{row?.deliveryDate ? moment(row?.deliveryDate).format('DD-MM-YYYY') : ''}</span>
            </td>
            <td className="text-center">
              <span>
                {moment(row?.slot?.startTime).format('HH:mm') +
                  '-' +
                  moment(row?.slot?.endTime).format('HH:mm')}
              </span>
            </td>
            <td className="text-center">
              <NavLink
                onClick={() => history.push(`/customers/${row?.customer?.id}`)}
                to={`/customers/${row?.customer?.id}`}>
                <span>{row?.customer?.firstName + ' ' + row?.customer?.lastName}</span>
              </NavLink>
            </td>
            <td className="text-center">
              <span>${row?.price + row?.price * (row?.tax / 100)}</span>
            </td>
            <td className="text-right">
              <Badge
                color={`neutral-${statusClass(row?.orderStatus)}`}
                className={`text-${statusClass(row?.orderStatus)} px-4`}>
                {row?.orderStatus}
              </Badge>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

OrdersTable.propTypes = {
  columns: PropTypes.array,
  orders: PropTypes.array
};

export default OrdersTable;
